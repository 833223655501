import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Img from 'gatsby-image'
import FluidImage from '../components/FluidImage'
import ContentPadding from '../components/ContentPadding'

export const BlogPostTemplate = ({
  content,
  previewContent,
  description,
  tags,
  title,
  header_image_crop_focus,
  full_image
}) => {
  return (
    <React.Fragment>
      {full_image.childImageSharp ? 
       <Img className={'header-img crop-focus-' + header_image_crop_focus} fluid={full_image.childImageSharp.fluid} alt="" />
      :
       <FluidImage className={'header-img crop-focus-' + header_image_crop_focus} src={full_image} alt=""/>
      }
      <ContentPadding>
        <Helmet title={`Voices Across Time | ${title}`} />
        <section className="section">
   
          <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
            {title}
          </h1>
          <p>{description}</p>
          {previewContent ?
            <Content className="has-text-justified content" content={previewContent} />
            :
            <HTMLContent content={content} className="has-text-justified content" />
          }
          {tags && tags.length ? (
            <div style={{ marginTop: `4rem` }}>
              <h4>Tags</h4>
              <ul className="taglist">
                {tags.map(tag => (
                  <li key={tag + `tag`}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
  
        </section>
      </ContentPadding>
    </React.Fragment>
  )
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        header_image_crop_focus={post.frontmatter.header_image_crop_focus}
        full_image={post.frontmatter.full_image}
      />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        full_image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 75, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        header_image_crop_focus
      }
    }
  }
`
